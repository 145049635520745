import KlineModal from '@/components/KlineModal'
import UFormItem from '@/components/UFormItem'
import { useStore } from '@/store'
import { http } from '@/utils'
import { AreaChartOutlined } from '@ant-design/icons'
import { App, Breadcrumb, Button, Card, Col, DatePicker, Divider, Flex, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Statistic, Table, TableColumnsType, Tag, Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import './index.css'

const { Countdown } = Statistic

const { Title, Link } = Typography

interface BatchSelectType {
  id: number
  name: string
}

interface T1DataType {
  id: number
  code: number
  code_name: string
  qty: number
  entry_price: number
  close: number
  latest_amount: number
  clear_price: number
  pnl: number
  roi: number
  daily_pnl: number
  daily_roi: number
  created_at: string
  end_at: string
  sug_tags: string[]
}

interface PositionType {
  total_amount: number
  total_pnl: number
  total_roi: number
  up_qty: number
  down_qty: number
  count: number
  total_daily_pnl: number
  list: T1DataType[]
}

interface BatchType {
  id: number
  name: string
  type: number
  type_cn: string
  status: number
  status_cn: string
  remark: string
  deadline: number
  position_qty: number
  created_at: string
  updated_at: string
}

interface KlineParamsType {
  batchId: number
  code: number
  codeName: string
  dateStart: string
  dateEnd: string
}

const Position: React.FC = () => {
  const { message } = App.useApp()
  const { positionBatchStore } = useStore()
  const { batchs, getBatchs } = positionBatchStore
  const [position, setPositionList] = useState<PositionType>({
    total_amount: 0,
    total_pnl: 0,
    total_roi: 0,
    up_qty: 0,
    down_qty: 0,
    count: 0,
    total_daily_pnl: 0,
    list: [],
  })

  const [batch, setBatch] = useState<BatchType>({
    id: 0,
    name: '',
    type: 0,
    type_cn: '',
    status: 0,
    status_cn: '',
    remark: '',
    deadline: 0,
    position_qty: 0,
    created_at: '',
    updated_at: '',
  })
  const [curBatchID, setCurBatchID] = useState<number>(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getBatchs()
  }, [getBatchs])

  const onChange = (value: number) => {
    const loadList = async () => {
      setLoading(true)
      try {
        const res = await http.post('/v1/position/list', { batch_id: value })
        const { list, total_count, total_amount, total_pnl, total_roi, total_daily_pnl, up_qty, down_qty } = res.data
        setPositionList({
          total_amount: total_amount,
          total_pnl: total_pnl,
          total_roi: total_roi,
          total_daily_pnl: total_daily_pnl,
          up_qty: up_qty,
          down_qty: down_qty,
          list: list,
          count: total_count,
        })
        setCurBatchID(value)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          message.error(error.response?.data?.message || '失败')
        }
      }
      setLoading(false)
    }
    loadList()

    const loadBatch = async () => {
      try {
        const res = await http.post('/v1/batch/getbyuser', { id: value })
        setBatch({ ...res.data })
        setCurBatchID(value)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          message.error(error.response?.data?.message || '失败')
        }
      }
    }

    loadBatch()
  }

  // 删除回调
  const delPosition = async (record: { id: number }) => {
    await http.post(`/v1/position/delete`, { id: record.id })
    onChange(curBatchID)
  }

  const t1Columns: TableColumnsType<T1DataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 110,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: '证券代码',
      dataIndex: 'code',
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: '证券名称',
      dataIndex: 'code_name',
      render: (text, record: T1DataType) => {
        return (
          <Link onClick={() => showKlineModal(record)}>
            {text} <AreaChartOutlined />
          </Link>
        )
      },
    },
    {
      title: '数量',
      dataIndex: 'qty',
      sorter: (a, b) => a.qty - b.qty,
    },
    {
      title: '买入价',
      dataIndex: 'entry_price',
      sorter: (a, b) => a.entry_price - b.entry_price,
    },
    {
      title: '最新价',
      dataIndex: 'close',
      sorter: (a, b) => a.close - b.close,
    },
    {
      title: '最新市值',
      dataIndex: 'latest_amount',
      sorter: (a, b) => a.latest_amount - b.latest_amount,
    },
    {
      title: '盈亏比例',
      dataIndex: 'roi',
      sorter: (a, b) => a.roi - b.roi,
      defaultSortOrder: 'descend',
      render: (text: number) => `${text}%`,
    },
    {
      title: '盈亏',
      dataIndex: 'pnl',
      sorter: (a, b) => a.pnl - b.pnl,
    },
    {
      title: '当日盈亏比例',
      dataIndex: 'daily_roi',
      sorter: (a, b) => a.daily_roi - b.daily_roi,
      render: (text: number) => (text !== 0 ? `${text}%` : '--'),
    },
    {
      title: '当日盈亏',
      dataIndex: 'daily_pnl',
      sorter: (a, b) => a.daily_pnl - b.daily_pnl,
      render: (text: number) => (text !== 0 ? text : '--'),
    },
    {
      title: '推荐操作',
      key: 'sug_tags',
      dataIndex: 'sug_tags',
      render: (_, { sug_tags }) => {
        if (sug_tags != null) {
          return (
            <>
              {sug_tags.map((tag) => {
                let color = ''
                let act = ''
                if (tag === 'stop_loss') {
                  color = 'red'
                  act = '考虑止损'
                }
                if (tag === 'target_profit') {
                  color = 'gold'
                  act = '止盈'
                }
                return (
                  <Tag
                    color={color}
                    key={tag}
                  >
                    {act}
                  </Tag>
                )
              })}
            </>
          )
        } else {
          return null
        }
      },
    },
    {
      title: '清仓价',
      dataIndex: 'clear_price',
      sorter: (a, b) => a.clear_price - b.clear_price,
      render: (text: number) => (text !== 0 ? text : '--'),
    },
    {
      title: '清仓时间',
      dataIndex: 'end_at',
      render: (text: string) => (text ? dayjs(text).format('YYYY-MM-DD') : '--'),
    },
    {
      title: '操作',
      render: (record: T1DataType) => {
        return (
          <Space size="middle">
            <Link onClick={() => showPublishModal(record)}>编辑</Link>
            <Popconfirm
              title="确认删除吗?"
              onConfirm={() => delPosition(record)}
              okText="确认"
              cancelText="取消"
            >
              <Link type="danger">删除</Link>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  // publish modal start
  const [openPublish, setPublishOpen] = useState(false)
  const [publishForm] = Form.useForm()
  const [isEditPublishModal, setIsEditPublishModal] = useState<boolean>(false)
  const showPublishModal = (record: T1DataType | null) => {
    if (record) {
      setIsEditPublishModal(true)
      const new_end_at = record.end_at != '' ? dayjs(record.end_at, 'YYYY/MM/DD') : null
      publishForm.setFieldsValue({ ...record, end_at: new_end_at })
    } else {
      setIsEditPublishModal(false)
      publishForm.resetFields()
    }
    setPublishOpen(true)
  }

  const onPublishSubmit = async () => {
    try {
      const values = await publishForm.validateFields()
      if (values.end_at != null) {
        values.end_at = dayjs(values.end_at).format('YYYY-MM-DD 00:00:00')
      }
      if (isEditPublishModal) {
        // 编辑接口
        await http.post(`/v1/position/update`, { ...values, batch_id: curBatchID })
      } else {
        // 新建接口
        await http.post(`/v1/position/create`, { ...values, batch_id: curBatchID })
      }
      onChange(curBatchID)
      message.success(`成功`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || '失败')
      }
    }
    setPublishOpen(false)
  }
  // publish modal end

  // import modal start
  const [importForm] = Form.useForm()
  const [importContent, setImportContent] = useState('')
  const [importOpen, setImportOpen] = useState(false)
  const onImportSubmit = async () => {
    try {
      const values = await importForm.validateFields()
      await http.post(`/v1/position/import`, { begin_at: dayjs(values.begin_at).format('YYYY-MM-DD 00:00:00'), batch_id: curBatchID, content: values.content })
      onChange(curBatchID)
      message.success(`导入成功`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || '导入失败')
      }
    }
    setImportOpen(false)
  }
  // import modal end

  // kline modal start
  const [klineOpen, setKlineOpen] = useState(false)
  const [klineParams, setKlineParams] = useState<KlineParamsType>({} as KlineParamsType)
  const showKlineModal = (record: T1DataType | null) => {
    if (record) {
      setKlineParams({
        batchId: curBatchID,
        code: record.code,
        codeName: record.code_name,
        dateStart: dayjs(record.created_at).subtract(365, 'day').hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss'),
        dateEnd: dayjs().hour(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:ss'),
      })
    }
    setKlineOpen(true)
  }
  // kline modal end

  return (
    <div className="postion">
      <Flex
        justify="space-between"
        gap="small"
        vertical
      >
        <Breadcrumb
          style={{ margin: '16px 0 5px 0' }}
          separator=">"
          items={[
            {
              title: '持仓',
            },
            {
              title: '持仓列表',
            },
          ]}
        />
        <Title
          level={4}
          style={{ margin: '5px 0 10px 0' }}
        >
          <Flex
            justify="flex-start"
            align="center"
            gap="small"
            vertical={false}
          >
            持仓列表
          </Flex>
        </Title>
        <Card>
          <Form>
            <Row gutter={24}>
              <Col span={8}>
                <UFormItem
                  label="股池批次"
                  name="batchs"
                >
                  <Select
                    placeholder="请选股池批次"
                    options={batchs.map((item: BatchSelectType) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    onChange={onChange}
                  ></Select>
                </UFormItem>
              </Col>
              <Col span={3}>
                <Button
                  type="primary"
                  onClick={() => onChange(curBatchID)}
                >
                  刷新
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>

        <Card styles={{ body: { padding: '0' } }}>
          <Flex
            justify="space-evenly"
            align="center"
            gap="middle"
          >
            <Countdown
              title="截止时间"
              value={batch.deadline}
              format="D天H时m分s秒"
              style={{ width: '210px' }}
            />
            <Divider
              type="vertical"
              style={{ height: '100px' }}
            />
            <Statistic
              title="持仓市值"
              value={position.total_amount}
              precision={2}
            />
            <Divider
              type="vertical"
              style={{ height: '100px' }}
            />
            <Statistic
              title="持仓盈亏"
              value={position.total_pnl}
              precision={2}
              valueStyle={position.total_pnl > 0 ? { color: '#cf1322' } : { color: '#3f8600' }}
            />
            <Divider
              type="vertical"
              style={{ height: '100px' }}
            />
            <Statistic
              title="盈亏比例"
              value={position.total_roi}
              precision={2}
              valueStyle={position.total_pnl > 0 ? { color: '#cf1322' } : { color: '#3f8600' }}
              suffix="%"
            />
            <Divider
              type="vertical"
              style={{ height: '100px' }}
            />
            <Statistic
              title="当日参考盈亏"
              value={position.total_daily_pnl}
              precision={2}
              valueStyle={position.total_daily_pnl > 0 ? { color: '#cf1322' } : { color: '#3f8600' }}
            />
            <Divider
              type="vertical"
              style={{ height: '100px' }}
            />
            <Statistic
              title="上涨"
              value={position.up_qty}
              valueStyle={{ color: '#cf1322' }}
            />
            <Statistic
              title="下跌"
              value={position.down_qty}
              valueStyle={{ color: '#3f8600' }}
            />
          </Flex>
        </Card>

        <Card>
          <Flex
            justify="space-between"
            style={{ padding: '0 0 16px 0' }}
          >
            <div>
              {position.count > 0 ? (
                <>
                  <Tag color="gold">{batch.status_cn}</Tag>
                  <Tag color="volcano">{batch.type_cn}</Tag>
                  <Tag color="blue">#{position.count}</Tag>
                </>
              ) : null}
            </div>
            <Flex
              justify="space-between"
              gap="middle"
            >
              <Button
                type="dashed"
                onClick={() => {
                  if (curBatchID === 0) {
                    alert('请选择股池批次')
                    return
                  }
                  setImportOpen(true)
                }}
              >
                导入
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  if (curBatchID === 0) {
                    alert('请选择股池批次')
                    return
                  }
                  showPublishModal(null)
                }}
              >
                新建
              </Button>
            </Flex>
          </Flex>
          <Table
            rowKey="id"
            columns={t1Columns}
            dataSource={position.list}
            size="small"
            pagination={false}
            loading={loading}
            rowClassName={(record) => {
              return record.pnl > 0 ? 'profit-up' : 'profit-down'
            }}
          />
        </Card>
      </Flex>

      <Modal
        title={isEditPublishModal ? '编辑' : '新建'}
        centered
        open={openPublish}
        onOk={() => onPublishSubmit()}
        onCancel={() => setPublishOpen(false)}
        width={500}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ type: 1 }}
          form={publishForm}
        >
          <Form.Item
            label="ID"
            name="id"
            hidden={true}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="证券代码"
            name="code"
            rules={[{ required: true, message: '请输入证券代码' }]}
          >
            <Input
              placeholder="请输入证券代码"
              style={{ width: 230 }}
              disabled={isEditPublishModal ? true : false}
            />
          </Form.Item>
          <Form.Item
            label="买入数量"
            name="qty"
            rules={[{ required: true, message: '请输入买入数量' }]}
          >
            <InputNumber
              placeholder="请输入买入数量"
              controls={false}
              style={{ width: 230 }}
            />
          </Form.Item>
          <Form.Item
            label="买入价"
            name="entry_price"
          >
            <InputNumber
              placeholder="请输入买入价"
              controls={false}
              style={{ width: 230 }}
            />
          </Form.Item>
          <Form.Item
            label="清仓价"
            name="clear_price"
          >
            <InputNumber
              placeholder="请输入清仓价"
              controls={false}
              style={{ width: 230 }}
            />
          </Form.Item>
          <Form.Item
            name="end_at"
            label="清仓日期"
          >
            <DatePicker />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="导入"
        centered
        open={importOpen}
        onOk={() => onImportSubmit()}
        onCancel={() => setImportOpen(false)}
        width={1000}
      >
        <Form
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 18 }}
          initialValues={{ type: 1 }}
          form={importForm}
        >
          <Form.Item
            name="begin_at"
            label="开始日期"
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="导入内容"
            name="content"
          >
            <TextArea
              value={importContent}
              onChange={(e) => setImportContent(e.target.value)}
              placeholder="请输入导入内容"
              autoSize={{ minRows: 6 }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <KlineModal
        key={new Date().valueOf()}
        open={klineOpen}
        onCancel={() => setKlineOpen(false)}
        params={klineParams}
      ></KlineModal>
    </div>
  )
}

export default Position
